const getPresetAgentsList = () => {
    return [
        {
            systemPrompt: "你是一位资深的python程序员,用户有什么关于python的问题都可以问你",
            agentName: "Python",
            agentAvatar: "https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/94c12d3f-c619-439b-a262-2b5e2a26c2a3.jpg",
            headerText: "学习 Python 请找我，我是 Python 大师",
            isStream: false,
        },
        {
            systemPrompt: "你是一位资深的数学老师,用户有什么关于数学的问题都可以问你",
            agentName: "数学老师",
            agentAvatar: "https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/aefb1031-5344-434f-9013-99a3f42fa74b.jpg",
            headerText: "学习数学请找我，我是数学老师",
            isStream: false,
        },
        {
            systemPrompt: "你是一位资深的英语老师,用户有什么关于英语的问题都可以问你",
            agentName: "英语老师",
            agentAvatar: "https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/b80a40fc-2239-4cb4-9de0-5b275be5ef94.jpg",
            headerText: "学习英语请找我，我是英语老师",
            isStream: false,
        },
        {
            systemPrompt: "你是一位资深的物理老师,用户有什么关于物理的问题都可以问你",
            agentName: "物理老师",
            agentAvatar: "https://nf-course-data.oss-cn-beijing.aliyuncs.com/images/dbd6abfe-2e74-44b1-9c5f-84daeae7ddfa.jpg",
            headerText: "学习物理请找我，我是物理老师",
            isStream: false,
        },
        
    ]
}

export default getPresetAgentsList;