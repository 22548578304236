import GptIcon from '@/assets/img/chatgpt.svg';
import TeacherIcon from '@/assets/img/teacherProfile.png';
import UserPng from '@/assets/img/user.png'
import styles from './aiChatRegion.module.less';
import { rewriteText, containsVariablePattern } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import AIteacherAvatar from '/assets/img/teacherProfile.svg';
import { ChatMessage } from '@/base/ChatMessage';
import AudioPlayingIcon from '@/assets/img/chat_component/audio_playing.svg';
import StopAudioIcon from '@/assets/img/chat_component/stop_audio.svg';
import PlayAudioIcon from '@/assets/img/chat_component/play_audio.svg';
import GenAudioLoading from '@/assets/img/chat_component/gen_audio_loading.svg';
import { Image } from 'antd';
import MarkdownWithHighlight from '@/components/MarkdownWithHighlight/MarkdownWithHighlight';
import { useState, useRef, useEffect } from 'react';
import MultimediaAPI from '@/api/multimedia';
import { message } from 'antd';

interface ChatBubbleProps {
    item: ChatMessage;
    role: string;
    userMessageAvatar?: string;
    isThisMsgPlaying?: boolean;
    scene?: string;
    isStream?: boolean;
    ttsVoice?: string;
    onLoad?: () => void;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
    item, role, userMessageAvatar, isThisMsgPlaying, scene, isStream, ttsVoice, onLoad
}) => {
    const { userInfoStore, ttsStore } = useStores();
    let mediaInfo = {};
    const userAvatar = userInfoStore.userInfoData.avatarUrl || UserPng;
    const [isHoveredAudioPlayingIcon, setIsHoveredAudioPlayingIcon] = useState(false);
    // 游乐场中的音频播放
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const [isGeneratingAudio, setIsGeneratingAudio] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(new Audio());
    const ttsAPI = new MultimediaAPI();



    const finalMessage = containsVariablePattern(item.rawContent) ? rewriteText(item.rawContent) : item.rawContent;

    const pauseAudio = () => {
        ttsStore.setUserNeedPause(true);
    }

    // 游乐场中的音频播放

    //icon显示，播放时显示暂停，暂停时显示播放，生成音频时显示加载
    const showAudioIcon = () => {
        if (isPlaying) {
            return isHoveredAudioPlayingIcon ? StopAudioIcon : AudioPlayingIcon;
        } else if (isGeneratingAudio) {
            return GenAudioLoading;
        } else {
            return PlayAudioIcon;
        }
    }
    const genVoice = async (text: string) => {
        let res: any
        try {
            setIsGeneratingAudio(true);
            res = await ttsAPI.genVoiceAndUpload(
                {
                    text: text,
                    voice_type: ttsVoice || 'BV700_streaming'
                }, 'tts');
            setAudioUrl(res.file_url);
            playPlaygroundAudio(res.file_url);
        } catch (error) {
            console.error('genVoiceAndUpload:', error);
            message.error('语音合成失败');
        } finally {
            setIsGeneratingAudio(false);
        }
    }

    const playPlaygroundAudio = (url: string) => {
        if (audioRef.current) {
            audioRef.current.src = url; // 设置为生成的音频源  
            audioRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch((error) => {
                console.error('播放失败:', error);
            });
            audioRef.current.onended = () => {
                setIsPlaying(false);
            }
        }
    };

    const pausePlaygroundAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
            ttsStore.setUserNeedPause(true);
        }
    };

    const handleAudioClick = async () => {
        if (isPlaying) {
            // 如果正在播放，暂停  
            pausePlaygroundAudio();
        } else if (isGeneratingAudio) {
            // 如果正在生成，不做处理  
            return;
        } else {
            // 如果没有生成音频，则进行生成  
            if (!audioUrl) {
                await genVoice(finalMessage);
            } else {
                // 如果已经生成了音频，直接播放  
                playPlaygroundAudio(audioUrl);
            }
        }
    };

    useEffect(() => {
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        }
    }, []);

    return (
        <>
            {item.type === 3 &&
                <div className={styles.userMessage}>
                    <div className={styles.chatBubbleContent}>{finalMessage}</div>
                    <img
                        src={userMessageAvatar === "teacher" ? AIteacherAvatar : userAvatar}
                        alt="user"
                        className={styles.chatBubbleIcon}
                    />
                </div>}
            {item.type === 1 &&
                <div className={styles.gptMessage}>
                    <div className={styles.chatBubbleIcon}>
                        <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" />
                        {isThisMsgPlaying
                            && <img
                                src={isHoveredAudioPlayingIcon ? StopAudioIcon : AudioPlayingIcon}
                                alt="audioPlaying"
                                className={styles.audioPlayingIcon}
                                onClick={pauseAudio}
                                onMouseEnter={() => setIsHoveredAudioPlayingIcon(true)}
                                onMouseLeave={() => setIsHoveredAudioPlayingIcon(false)}
                            />}
                        {
                            (scene === "playground" && !isStream) && <img
                                src={showAudioIcon()}
                                alt="audioPlaying"
                                className={styles.audioPlayingIcon}
                                onClick={handleAudioClick}
                                onMouseEnter={() => setIsHoveredAudioPlayingIcon(true)}
                                onMouseLeave={() => setIsHoveredAudioPlayingIcon(false)}
                            />
                        }
                    </div>

                    <div
                        className={styles.chatBubbleContent}
                        style={{
                            border: scene === "playground" ? "1px solid #0000001F" : "",
                            background: scene === "playground" ? "#F8FAFC" : ""
                        }}
                    >
                        <MarkdownWithHighlight content={finalMessage} />
                    </div>
                </div>
            }
            {
                item.type === 4 &&
                <div className={styles.gptMessage}>
                    <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" className={styles.chatBubbleIcon} />
                    <div className={styles.chatBubbleContent}>
                        {item.mediaInfo && item.mediaInfo.mediaType === "image" &&
                            <Image
                                src={item.mediaInfo.mediaUrl} alt="media"
                                className={styles.mediaContent}
                                style={{
                                    maxWidth: scene === "playground" ? "40rem" : "",
                                    width: scene === "playground_loading" ? "5rem" : "",
                                }}
                                onLoad={onLoad}
                            />
                        }
                    </div>
                </div>
            }
            {
                item.type === 5 &&
                <div className={styles.gptMessage}>
                    <img src={role === "gpt" ? GptIcon : TeacherIcon} alt="gpt" className={styles.chatBubbleIcon} />
                    <div className={styles.chatBubbleContent}>
                        {item.mediaInfo && item.mediaInfo.mediaType === "video" &&
                            <video
                                style={{ width: '100%', height: '100%' }}
                                src={item.mediaInfo.mediaUrl}
                                controls
                                onLoadedData={onLoad}
                            />
                        }
                    </div>
                </div>
            }
        </>
    )
}
export default observer(ChatBubble);
