import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckUserName from "@/pages/CheckUserName/CheckUserName";
import { Navigate } from "react-router-dom";
import Share from "@/pages/Share/Share";
import LoginV2 from "@/pages/LoginV2/LoginV2";
import PlayGround from "@/pages/PlayGround/PlayGround";
import AIChat from "@/pages/PlayGround/AIChat/AIChat";
import PresetAgents from "@/pages/PlayGround/AIChat/PresetAgents/PresetAgents";
import ChatPage from "@/pages/PlayGround/AIChat/ChatPage/ChatPage";
import BoboMusic from "@/pages/PlayGround/BoboMusic/BoboMusic";

import { ReactNode } from "react";

function AuthRoute({ children, ...rest }: { children: ReactNode }) {
    const isLoggedIn = Boolean(localStorage.getItem('token'));
    return isLoggedIn ? children : <Navigate to="/login" />;
}

function RootRoute() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<CheckUserName />}>
                    <Route index element={<Navigate to="/playground/bobo-music" />} />
                    <Route path="/playground" element={<AuthRoute><PlayGround /></AuthRoute>} >
                        <Route path="/playground/bobo-music" element={<AuthRoute><BoboMusic /></AuthRoute>} />
                        <Route path="/playground/chat" element={<AuthRoute><AIChat /></AuthRoute>} >
                            <Route path="/playground/chat/discover" element={<PresetAgents />} />
                            <Route path="/playground/chat/:md5" element={<ChatPage />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="/share/:md5" element={<Share />} />
                <Route path="/login" element={<LoginV2 />} />
            </Routes>
        </Router>
    );
}

export default RootRoute;
