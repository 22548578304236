import { useState, useEffect, useRef } from 'react'
import styles from './boboMusic.module.less';
import GenDescMusic from './GenDescMusic/GenDescMusic';
import GenCustomMusic from './GenCustomMusic/GenCustomMusic';
import { useNavigate } from 'react-router-dom';
import SongItem from './SongItem/SongItem';
import MusicControl from './MusicControl/MusicControl';
import MusicProgress from './MusicProgress/MusicProgress';
import MusicVolume from './MusicVolume/MusicVolume';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { getAlbum, createAlbum, updateAlbum } from '@/api/playgroundMusic';
import SongData from '@/base/SongData';
import DataDefinition from '@/base/DataDefinition';
import { convertUrl } from '@/utils/utils';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';

const BoboMusic: React.FC = observer(() => {
    const { userInfoStore } = useStores();
    const navigate = useNavigate();
    const [mode, setMode] = useState('inspiration');
    const [songList, setSongList] = useState<SongData[]>([])
    const [currentSong, setCurrentSong] = useState<SongData>(new SongData());
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(100);
    const [progress, setProgress] = useState(0);
    const [formattedCurrentTime, setFormattedCurrentTime] = useState("00:00");
    const [formattedDuration, setFormattedDuration] = useState("00:00");
    const [albumInfo, setAlbumInfo] = useState({});
    const playPromise = useRef<Promise<void> | undefined>(undefined);

    const audioRef = useRef<HTMLAudioElement>(null);

    const changeMode = (mode: string) => {
        setMode(mode);
    }

    const playMusic = () => {
        playPromise.current = audioRef.current!.play();
        setIsPlaying(true);
    }

    const pauseMusic = () => {
        if (playPromise.current !== undefined) {
            playPromise.current.then(() => {
                audioRef.current!.pause();
            }).catch((error) => {
                console.log(error);
            });
            setIsPlaying(false);
        }
    }

    function formatTime(timeInSeconds: number) {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${paddedSeconds}`;
    }

    // 播放结束时的回调函数
    const handleSongEnded = () => {
        if (songList.length <= 1) {
            setIsPlaying(false); // 如果只有一首歌，停止播放
        } else {
            handleNextSong(); // 播放下一首歌
        }
    };

    const handlePreSong = () => {
        if (songList.length <= 1) return;

        const currentIndex = songList.findIndex((song) => song.id === currentSong!.id);

        if (currentIndex === 0) {
            setCurrentSong(songList[songList.length - 1]);
        } else {
            setCurrentSong(songList[currentIndex - 1]);
        }
    };

    const handleNextSong = () => {
        if (songList.length <= 1) return;

        const currentIndex = songList.findIndex((song) => song.id === currentSong!.id);

        if (currentIndex === songList.length - 1) {
            setCurrentSong(songList[0]);
        } else {
            setCurrentSong(songList[currentIndex + 1]);
        }
    };

    //音量控制
    const handleVolumeChange = (value: number) => {
        if (audioRef.current) {
            audioRef.current.volume = value / 100;
        }
        setVolume(value);
    }
    //进度条控制
    const handleProgressChange = (value: number) => {
        const audio = audioRef.current!;
        const newTime = (audio.duration / 100) * value;
        audio.currentTime = newTime;
        setProgress(value);
        setFormattedCurrentTime(formatTime(newTime));
    }
    // 创建用户专辑
    const createUserAlbum = async () => {
        const res: any = await createAlbum({
            id: 0,
            name: "string",
            author_id: userInfoStore.userInfoData.id,
            songs: [],
            update_time: 0
        });
        if (res.status === 'success') {
            getAlbumList();
        }
    }
    // 获取用户专辑列表
    const getAlbumList = async () => {
        const res: any = await getAlbum(userInfoStore.userInfoData.id);
        const userName = userInfoStore.userInfoData.name;
        if (res.status === 'success') {
            setAlbumInfo(res.data);

            //把每一项都转化为camelCase
            res.data.songs = res.data.songs.map((song: any) => DataDefinition.toCamelCaseObj(song));
            res.data.songs = res.data.songs.map((song: any) => new SongData(song));
            for (let i = 0; i < res.data.songs.length; i++) {
                res.data.songs[i].author = userName;
            }
            setSongList(res.data.songs);
            if (res.data.songs.length > 0 && !currentSong!.id) {
                setCurrentSong(res.data.songs[0]);
            }
        } else if (res.status === 'error') {
            createUserAlbum();
        }
    }
    //删除专辑中的歌曲
    const deleteSong = async (songId: number) => {
        const newSongList = songList.filter((song) => song.id !== songId);
        const requestParams = {
            ...albumInfo,
            songs: newSongList
        }
        const res: any = await updateAlbum(requestParams);
        if (res.status === 'success') {
            getAlbumList();
            if (newSongList.length > 0) {
                if (currentSong!.id === songId) {
                    // 如果删除的是当前播放的歌曲,则把当前播放的歌曲设置为第一首，并且停止播放
                    setCurrentSong(newSongList[0]);
                    setIsPlaying(false);
                }
            } else {
                // 如果删除的是最后一首歌曲，则停止播放
                setIsPlaying(false);
                setCurrentSong(new SongData());
            }
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id) {
            getAlbumList();
        }
    }, [userInfoStore.userInfoData.id]);

    useEffect(() => {
        const audioElement = audioRef.current;
        if (audioElement) {
            audioElement.addEventListener('ended', handleSongEnded);
            return () => {
                audioElement.removeEventListener('ended', handleSongEnded);
            };
        }
    }, [currentSong]);


    useEffect(() => {
        if (currentSong!.musicUrl && isPlaying) {
            playMusic();
        }
    }, [currentSong!.musicUrl, isPlaying]);

    useEffect(() => {
        setProgress(0);
        const audio = audioRef.current!;

        const updateProgress = () => {
            const { currentTime, duration } = audio;
            setProgress((currentTime / duration) * 100);
            setFormattedCurrentTime(formatTime(currentTime)); // 确保这里更新了 formattedCurrentTime
        }

        audio.addEventListener('timeupdate', updateProgress);

        // 设置初始值
        const setAudioData = () => {
            setFormattedDuration(formatTime(audio.duration)); // 当音频的 duration 变得可用时设置总时长
        };

        if (audio.readyState > 0) {
            // 如果audio元素在这个effect运行时已经加载好了（例如，快速切换组件），立即设置时长
            setAudioData();
        } else {
            audio.addEventListener('loadedmetadata', setAudioData);
        }

        // 清理监听器
        return () => {
            audio.removeEventListener('timeupdate', updateProgress);
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, [currentSong!.musicUrl])
    return (
        <div className={styles.boboMusicBox}>
            <div className={styles.createMusicBox}>
                <div className={styles.modeBox}>
                    <div className={styles.chooseModeTab}>
                        <div
                            className={`${styles.modeTabItem} ${mode === 'inspiration' ? styles.selected : ''}`}
                            onClick={() => changeMode('inspiration')}
                        >
                            灵感创作
                        </div>
                        <div
                            className={`${styles.modeTabItem} ${mode === 'custom' ? styles.selected : ''}`}
                            onClick={() => changeMode('custom')}
                        >
                            精准定制
                        </div>
                    </div>
                </div>
                <div className={styles.genBox}>
                    {
                        mode === 'inspiration' && (
                            <GenDescMusic
                                userId={userInfoStore.userInfoData.id}
                                getAlbumList={getAlbumList}
                            />
                        )
                    }
                    {
                        mode === 'custom' && (
                            <GenCustomMusic
                                userId={userInfoStore.userInfoData.id}
                                getAlbumList={getAlbumList}
                            />
                        )
                    }
                </div>
            </div>
            <div className={styles.playMusicBox}>
                <div className={styles.content}>
                    <div className={styles.songList}>
                        {
                            songList.map((song) => {
                                return (
                                    <SongItem
                                        key={song.id}
                                        song={song}
                                        currentSong={currentSong}
                                        setCurrentSong={setCurrentSong}
                                        isPlaying={isPlaying}
                                        playMusic={playMusic}
                                        pauseMusic={pauseMusic}
                                        deleteSong={deleteSong}
                                        userId={userInfoStore.userInfoData.id}
                                    />
                                )
                            })
                        }
                        {
                            songList.length === 0 && (
                                <div className={styles.noSongBox}>
                                    <div className={styles.noSongText}>
                                        你还没有歌曲哦，快去创作吧~
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.currentSongInfo}>
                        <div className={styles.songCover}>
                            {
                                currentSong!.coverUrl && (<img src={currentSong!.coverUrl} />)
                            }
                            {
                                !currentSong!.coverUrl && (
                                    <div className={styles.noCover}>
                                        <div className={styles.noCoverText}>
                                            暂无封面
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={styles.songText}>
                            <div className={styles.songName}>
                                {currentSong!.name}
                            </div>
                            <div className={styles.songAuthor}>
                                作者: {userInfoStore.userInfoData.name}
                            </div>
                            <div className={styles.lyrics}>
                                {currentSong!.lyrics}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.playArea}>
                    <div className={styles.musicPlayerBox}>
                        <MusicControl
                            isPlaying={isPlaying}
                            playMusic={playMusic}
                            pauseMusic={pauseMusic}
                            handlePreSong={handlePreSong}
                            handleNextSong={handleNextSong}
                        />
                        <MusicProgress
                            progress={progress}
                            handleProgressChange={handleProgressChange}
                            formattedCurrentTime={formattedCurrentTime}
                            formattedDuration={formattedDuration}
                        />
                        <MusicVolume
                            volume={volume}
                            handleVolumeChange={handleVolumeChange}
                        />
                    </div>
                    <audio src={convertUrl(currentSong!.musicUrl)} ref={audioRef} />
                </div>
            </div>
        </div>
    )
});

export default BoboMusic;