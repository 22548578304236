import React from 'react';
import styles from './loginV2.module.less';
import { useState } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import service from "@/services/axios";
import RobotLogo from "@/assets/img/robot_logo.svg";

const LoginV2: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm = searchParams.get("utm"); // 获取 utm 参数的值]
    const [phone, setPhone] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [isCodeBtnDisabled, setCodeBtnDisabled] = useState(false);// 获取验证码按钮是否禁用
    const [countdown, setCountdown] = useState(60); // 再次获取验证码倒计时秒数
    const [phoneError, setPhoneError] = useState("");
    const [verifyCodeError, setVerifyCodeError] = useState("");
    const navigate = useNavigate();


    const validatePhone = () => {
        if (!phone.trim()) {
            setPhoneError("手机号不能为空");
            return false;
        }
        if (!/^1[3456789]\d{9}$/.test(phone)) {
            setPhoneError("手机号格式不正确");
            return false;
        }
        setPhoneError("");
        return true;
    };

    const validateVerifyCode = () => {
        if (!verifyCode.trim()) {
            setVerifyCodeError("验证码不能为空");
            return false;
        }
        setVerifyCodeError("");
        return true;
    };

    const onGetCode = async () => {
        const isPhoneValid = validatePhone();
        if (!isPhoneValid) {
            return;
        }
        setCodeBtnDisabled(true);
        const data = {
            sms_account: "7e7ce07d",
            phone_number: phone,
            sign: "神元力量",
            template_id: "ST_810192b9",
        };
        //获取验证码
        try {
            const res: any = await service.post("/send_sms_verify_code", data);
            if (res.status === "success") {
                message.success("验证码已发送");

                setCountdown(60);

                const timer = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev === 1) {
                            clearInterval(timer);
                            setCodeBtnDisabled(false);
                            return 60; // 重置为初始倒计时秒数  
                        }
                        return prev - 1;
                    });
                }, 1000);
            } else {
                message.error("获取验证码失败，请稍后再试");
                setCodeBtnDisabled(false);
            }
        } catch (error) {
            console.warn(error);
            message.error("获取验证码异常，请稍后再试");
            setCodeBtnDisabled(false);
        }
    };

    const onPhoneLogin = async () => {
        const isPhoneValid = validatePhone();
        const isVerifyCodeValid = validateVerifyCode();

        if (!isPhoneValid || !isVerifyCodeValid) {
            return;
        }
        const data = {
            sms_account: "7e7ce07d",
            phone_number: phone,
            code: verifyCode,
            scene: "注册验证码",
            type: 0
        };
        //手机登录
        try {
            const res: any = await service.post("/check_sms_verify_code", data);
            if (res.status === "success" && res.data.Result === "0") {
                const loginRes: any = await service.post("/signup_and_login_phone", { phone: phone, utm: utm ? utm : "" });
                if (loginRes.status === "success") {
                    message.success("登录成功", 1.5);
                    localStorage.setItem("token", loginRes.data.access_token);
                    navigate("/");
                } else {
                    message.error("登录失败，请稍后再试");
                }
            } else {
                message.error("验证码错误，请重新输入");
            }
        } catch (error) {
            console.warn(error);
            message.error("登录异常，请稍后再试");
        }
    };
    return (
        <div className={styles.loginV2Page}>
            <div className={styles.welcomeBox}>
                <div className={styles.welcomeText}>小研究员您好！</div>
                <div className={styles.welcomeSubText}>欢迎来到开窍AI 实验室游乐场</div>
            </div>
            <div className={styles.LoginBox}>
                <div className={styles.topArea}>
                    学员登录
                </div>
                <div className={styles.phoneBox}>
                    <div className={styles.inputBox}>
                        <input
                            type="text"
                            placeholder="请输入学员手机号"
                            className={styles.input}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            onBlur={validatePhone}
                        />
                        {phoneError && <div className={styles.errorText}>{phoneError}</div>}
                    </div>
                    <div className={styles.getCodeBox}>
                        <input
                            type="text"
                            placeholder="请输入短信验证码"
                            className={styles.input}
                            value={verifyCode}
                            onChange={(e) => setVerifyCode(e.target.value)}
                            onBlur={validateVerifyCode}
                        />
                        <Button
                            className={`${styles.getCodeBtn} ${phone.trim() !== "" && /^1[3456789]\d{9}$/.test(phone) ? styles.getCodeBtnActive : ""}`}
                            onClick={onGetCode}
                            disabled={isCodeBtnDisabled}
                        >
                            {isCodeBtnDisabled ? `${countdown}s后重新获取` : '获取验证码'}
                        </Button>
                        {verifyCodeError && <div className={styles.errorText}>{verifyCodeError}</div>}
                    </div>

                    <Button className={styles.loginBtn} onClick={onPhoneLogin}>登录</Button>
                    <div className={styles.agreement}>
                        登录视为您已阅读并同意 <span>用户协议</span> 和 <span>隐私政策</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LoginV2;