// UserProfile.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './userProfileMenu.module.less';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';

const UserProfileMenu: React.FC = () => {
    const navigate = useNavigate();
    const { userInfoStore } = useStores();

    return (
        <div className={styles.userProfileMenu}>
            <div className={styles.userInfo}>
                <div className={styles.userName}>
                    学员:{userInfoStore.userInfoData.name}
                </div>
                <div className={styles.userClass}>
                    {userInfoStore.userInfoData.studentClass}
                </div>
                <div className={styles.userValidDate}>
                    <div>学籍有效期截至：</div>
                    <div>
                        {new Date(userInfoStore.userInfoData.validDate).toLocaleDateString()}
                    </div>
                </div>
            </div>
            <div
                className={styles.logout}
                onClick={() => {
                    localStorage.clear();
                    navigate('/login');
                }}
                >
                退出登录
            </div>
        </div>

    );
};

export default observer(UserProfileMenu);
