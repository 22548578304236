import React, { useState, useEffect } from "react";
import styles from "./playGround.module.less";
import { observer } from 'mobx-react-lite';
import RobotLogo from '@/assets/img/robot_logo.svg';
import { Layout, Tooltip } from 'antd';
import UserProfileMenu from '@/components/UserProfileMenu/UserProfileMenu';
import { useNavigate, Outlet } from "react-router-dom";

const { Header } = Layout;

const PlayGround: React.FC = () => {
    const navigate = useNavigate();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [activeKey, setActiveKey] = useState('bobo-music');

    const closeTooltip = () => {
        setTooltipVisible(false);
    }

    // 选择一个函数来判断当前路由对应哪个按钮  
    const isActive = () => {
        const currentPath = window.location.pathname;
        if (currentPath.includes('/playground/bobo-music')) {
            setActiveKey('bobo-music');
        } else if (currentPath.includes('/playground/chat')) {
            setActiveKey('chat');
        }
    }

    // 每次路由变化时调用isActive函数
    useEffect(() => {
        isActive();
    }, [window.location.pathname]);

    return (
        <>
            <div className={styles.container}>
                <Header className={styles.head}>
                    <div className={styles.topBarLogo}>
                        <img src={RobotLogo} alt="艾宝AI" />
                        <span>艾宝AI</span>
                    </div>
                    <div className={styles.selectBox}>
                        <div
                            className={`${styles.selectItem} ${activeKey === "bobo-music" ? styles.selectItemActive : ''}`}
                            onClick={() => navigate('/playground/bobo-music')}
                        >
                            <span className={styles.selectItemText}>AI写歌</span>
                        </div>
                        <div
                            className={`${styles.selectItem} ${activeKey === "chat" ? styles.selectItemActive : ''}`}
                            onClick={() => navigate('/playground/chat')}
                        >
                            <span className={styles.selectItemText}>AI聊天</span>
                        </div>
                    </div>
                    <div className={styles.rightArea}>
                        <Tooltip
                            title={<UserProfileMenu />}
                            trigger='click'
                            color='#ffffff'
                            open={tooltipVisible}
                            onOpenChange={(visible) => setTooltipVisible(visible)}
                        >
                            <div className={styles.userInfo}>
                                个人中心
                            </div>
                        </Tooltip>
                    </div>
                </Header>
                <Outlet />
            </div>
        </>
    )
}

export default observer(PlayGround);