import React, { useEffect, useState } from 'react';
import styles from './aiChat.module.less';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store/useStores';
import { createUserWork, getAllUserWorks, deleteUserWork } from '@/api/userWork';
import { useParams } from 'react-router-dom';
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import DataDefinition from "@/base/DataDefinition";
import { useNavigate, Outlet } from 'react-router-dom';
import { generateMD5 } from '@/utils/utils';
import HiIcon from '@/assets/img/chat_region_hi.svg';
import RecentChatIcon from '@/assets/img/playground/chat_recent_chat.svg';
import IntelligentAgentIcon from '@/assets/img/playground/chat_intelligent_agent.svg';
import MoreOperationsIcon from '@/assets/img/playground/chat_more_operations.svg';
import { Popover } from 'antd';
import GoBackIcon from '@/assets/img/user_center/go_back.svg';
import ChatData from '@/base/PlaygroundData/ChatData';

const AIChat: React.FC = () => {
    const { md5 } = useParams();
    const navigate = useNavigate();
    const { userInfoStore, playgroundStore } = useStores();
    const [chatHistoryList, setChatHistoryList] = useState<ChatData[]>([]);
    const [currentChatInfo, setCurrentChatInfo] = useState<ChatData | null>(null);

    //创建新的聊天记录
    const createNewDefaultChat = async (md5: string) => {
        const newChatAgent = new ChatData({
            md5: md5,
            userId: userInfoStore.userInfoData.id,
            userName: userInfoStore.userInfoData.name,
            workType: "playground_chat",
            workInfo: new AIChatRegionContent({
                headerText: `你好${userInfoStore.userInfoData.name ? `,${userInfoStore.userInfoData.name}` : ""}，我是你的AI助手`,
                isStream: true,
                chatTools: ''
            }),
        });
        const createRes: any = await createUserWork(newChatAgent);
        if (createRes.status === 'success') {
            createRes.data = new ChatData(DataDefinition.toCamelCaseObj(createRes.data));
            return createRes.data;
        }
        throw new Error("创建聊天记录失败");
    };
    //获取所有的聊天记录
    const getAllChatHistory = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_chat");
            if (res.status === "success") {
                const historyItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (historyItems.length > 0) {
                    setChatHistoryList(historyItems.reverse());
                    //如果URL中没有md5，并且不是discover页面，就跳转到第一个聊天记录
                    if (!md5 && !window.location.href.includes("discover")) {
                        navigate(`/playground/chat/${historyItems[0].md5}`);
                    }
                } else {
                    const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat0`));
                    setChatHistoryList([newChat]);
                    navigate(`/playground/chat/${newChat.md5}`);
                }
            } else if (res.status === "error") {
                const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat0`));
                setChatHistoryList([newChat]);
                navigate(`/playground/chat/${newChat.md5}`);
            }
        } catch (error) {
            console.error(error);
            // 这里可以选择显示错误提示给用户  
        }
    };

    //获取所有的智能体
    const getAllAgents = async () => {
        try {
            const res: any = await getAllUserWorks(userInfoStore.userInfoData.id, "playground_agent");
            if (res.status === "success") {
                const agentItems = res.data.map((item: any) => new ChatData(DataDefinition.toCamelCaseObj(item)));
                if (agentItems.length > 0) {
                    playgroundStore.setUserAgentList(agentItems.reverse());
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChatItemClick = (item: any) => {
        navigate(`/playground/chat/${item.md5}`); // 更新 URL  
    };

    const createNewChat = async () => {
        //用最后一个聊天记录的ID+1生成md5,因为是到最前面插入，所以是chatHistoryList[0].id+1
        const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat${chatHistoryList[0].id + 1}`));
        setChatHistoryList([newChat, ...chatHistoryList]);
        navigate(`/playground/chat/${newChat.md5}`);
    }

    //删除聊天或者智能体
    const deleteChatOrAgent = async (userId: number, md5: string, workType: string) => {
        try {
            const res: any = await deleteUserWork(userId, md5);
            if (res.status === "success") {
                if (workType === "playground_chat") {
                    const newChatHistoryList = chatHistoryList.filter((item: any) => item.md5 !== md5);
                    setChatHistoryList(newChatHistoryList);
                    if (currentChatInfo && currentChatInfo.md5 === md5) {
                        if (newChatHistoryList.length > 0) {
                            navigate(`/playground/chat/${newChatHistoryList[0].md5}`);
                        } else {
                            const newChat = await createNewDefaultChat(generateMD5(`${userInfoStore.userInfoData.id}@@default_chat0`));
                            setChatHistoryList([newChat]);
                            navigate(`/playground/chat/${newChat.md5}`);
                        }
                    }
                } else if (workType === "playground_agent") {
                    const newAgentList = playgroundStore.userAgentList.filter((item: any) => item.md5 !== md5);
                    playgroundStore.setUserAgentList(newAgentList);
                    if (currentChatInfo && currentChatInfo.md5 === md5) {
                        if (newAgentList.length > 0) {
                            navigate(`/playground/chat/${newAgentList[0].md5}`);
                        } else {
                            navigate(`/playground/chat/${chatHistoryList[0].md5}`);
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (userInfoStore.userInfoData.id === 0) return;
        getAllChatHistory();
        getAllAgents();
    }, [userInfoStore.userInfoData.id]);

    useEffect(() => {
        if (md5) {
            //根据md5从聊天记录或者智能体中找到对应的数据
            const currentChat = chatHistoryList.find((item: any) => item.md5 === md5);
            if (currentChat) {
                setCurrentChatInfo(currentChat);
            } else {
                const currentAgent = playgroundStore.userAgentList.find((item: any) => item.md5 === md5);
                if (currentAgent) {
                    setCurrentChatInfo(currentAgent);
                }
            }
        }
    }, [md5, chatHistoryList, playgroundStore.userAgentList]);

    //更多操作的列表组件
    const moreOperationsList = ({ userId, md5, workType }: any) => {
        return (
            <div className={styles.moreOperationsList}>
                <div
                    className={styles.operationItem}
                    onClick={() => deleteChatOrAgent(userId, md5, workType)}
                >
                    删除
                </div>
            </div>
        )
    }
    const handleMoreOperationsClick = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation(); // Prevents the click event from bubbling up  
    }

    return (
        <div className={styles.chatPageBox}>
            <div className={styles.sidebarBox}>
                <div className={styles.contentBox}>
                    <div className={styles.header}>
                        <img src={HiIcon} alt="hi" />
                        <div className={styles.title}>AI对话</div>
                    </div>
                    <div className={styles.historyBox}>
                        <div className={styles.recentChat}>
                            <img src={RecentChatIcon} alt="recent chat" />
                            <div className={styles.recentChatTitle}>最近对话</div>
                        </div>
                        <div
                            className={styles.createDefaultChatBtn}
                            onClick={createNewChat}
                        >
                            + 新建聊天
                        </div>
                        <div className={styles.historyList}>
                            {chatHistoryList.map((item: ChatData) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleChatItemClick(item)}
                                    className={styles.historyItem}
                                    style={{
                                        backgroundColor: currentChatInfo && currentChatInfo.id === item.id ? "#EEF2F6" : "",
                                    }}
                                >
                                    {item.workInfo.chatList.length > 0 ? item.workInfo.chatList[0].rawContent : "新对话"}
                                    <Popover
                                        content={moreOperationsList({ userId: item.userId, md5: item.md5, workType: item.workType })}
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName={styles.moreOperationsPopover}
                                    >
                                        <img src={MoreOperationsIcon}
                                            alt="more operations"
                                            className={styles.moreOperations}
                                            onClick={handleMoreOperationsClick}
                                        />
                                    </Popover>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.agentBox}>
                        <div className={styles.intelligentAgent}>
                            <img src={IntelligentAgentIcon} alt="intelligent agent" />
                            <div className={styles.intelligentAgentTitle}>我的智能体</div>
                        </div>
                        <div
                            className={styles.createDefaultChatBtn}
                            onClick={() => { navigate("/playground/chat/discover") }}
                        >
                            + 发现AI智能体
                        </div>
                        <div className={styles.agentList}>
                            {playgroundStore.userAgentList.map((item: ChatData) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleChatItemClick(item)}
                                    className={styles.agentItem}
                                    style={{
                                        backgroundColor: currentChatInfo && currentChatInfo.id === item.id ? "#EEF2F6" : "",
                                    }}
                                >
                                    {
                                        item.workInfo.agentAvatar ?
                                            <img src={item.workInfo.agentAvatar} className={styles.agentAvatar} /> :
                                            <div className={styles.agentAvatar} />
                                    }
                                    {item.workInfo.agentName}

                                    <Popover
                                        content={moreOperationsList({ userId: item.userId, md5: item.md5, workType: item.workType })}
                                        trigger="click"
                                        placement="bottomRight"
                                        overlayClassName={styles.moreOperationsPopover}
                                    >
                                        <img src={MoreOperationsIcon}
                                            alt="more operations"
                                            className={styles.moreOperations}
                                            onClick={handleMoreOperationsClick}
                                        />
                                    </Popover>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.chatPage}>
                <Outlet />
            </div>
        </div>
    )
}

export default observer(AIChat);